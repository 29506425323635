import React from 'react';
import image4 from '../components/img/cartoon.png'
import imagews from '../components/img/Digital_Glyph_Green.png'
import Canonical from '../components/Canonical'
import { useState} from 'react';
import { useEffect} from 'react'
import {useNavigate } from 'react-router-dom';
import { Link as ScrollLink, Element } from 'react-scroll';
import { useQuery } from '@apollo/client';
import {P_DATES} from '../utils/queries'
import {D_DATES} from '../utils/queries'
import Chocolate from '../components/Cakes/Chocolate'
import Banana from '../components/Cakes/Banana'
import Cakes from '../components/Cakes/Cakesint'
import Carrot from '../components/Cakes/Carrot'
import Pineapple from '../components/Cakes/Pineapple'
import Vanilla from '../components/Cakes/Vanilla'
import Cookiealmonds from '../components/cookies/Cookiealmonds'
import Cookiechoco from '../components/cookies/Cookiechoco'
import Cookiemocha from '../components/cookies/Cookiemocha'
import Tiramisuorder from '../components/Deserts/Tiramisu'
import Tiramisu from '../components/Deserts/Tiramisuint'
import Cookies from '../components/cookies/Cookiesint'


const Home = ({
setorder,
setSubtotal,
setOrderDescription,
setdlvdates,
setpcpddates,
}) => 
{

const { data: data4, loading:loading4} = useQuery(P_DATES);
const { data: data5, loading:loading5} = useQuery(D_DATES);

const cakeson = true

const Pdates = data4?.getPdatesCount?.PuPDates;
const Ddates = data5?.getDdatesCount?.DeliveryDates;

const navigate = useNavigate();
const [isOrder, setisOrder] = useState(false);

// cakes
const [chocolateTotal, setChocolateTotal] = useState(0); 
const [chocolateSize, setChocolateSize] = useState(''); 
const [chocolatequantity, setChocolateQuantity] = useState(0);

const [bananaTotal, setBananaTotal] = useState(0); 
const [bananaSize, setBananaSize] = useState(''); 
const [bananaquantity, setBananaQuantity] = useState(0);

const [carrotTotal, setCarrotTotal] = useState(0); 
const [carrotSize, setCarrotSize] = useState(''); 
const [carrotquantity, setCarrotQuantity] = useState(0);

const [pineTotal, setPineTotal] = useState(0); 
const [pineSize, setPineSize] = useState(''); 
const [pinequantity, setPineQuantity] = useState(0);

const [vanTotal, setVanTotal] = useState(0); 
const [vanSize, setVanSize] = useState(''); 
const [vanquantity, setVanQuantity] = useState(0);

const [Total,setTotal] = useState(0)

const [chFrostyOption, setChFrostyOption] = useState('');
const [chToppings, setChToppings] = useState([]);

const [banFrostyOption, setBanFrostyOption] = useState('');
const [banToppings, setBanToppings] = useState([]);

const [carrFrostyOption, setCarrFrostyOption] = useState('');
const [carrToppings, setCarrToppings] = useState([]);

const [pineFrostyOption, setPineFrostyOption] = useState('');
const [pineToppings, setPineToppings] = useState([]);

const [vanFrostyOption, setVanFrostyOption] = useState('');
const [vanToppings, setVanToppings] = useState([]);

const [chSweetener, setChSweetener] = useState('Coconut Sugar');

const [banSweetener, setBanSweetener] = useState('Coconut Sugar');

const [carrSweetener, setCarrSweetener] = useState('Coconut Sugar');

const [pineSweetener, setPineSweetener] = useState('Coconut Sugar');

const [vanSweetener, setVanSweetener] = useState('Coconut Sugar');
// cakes

const[cookieAlmQty, setCookieAlmQty]=useState(0)
const[cookieAlmTotal, setCookieAlmTotal]=useState(0)
const[cookieChocQty, setCookieChocQty]=useState(0)
const[cookieChocTotal, setCookieChocTotal]=useState(0)
const[cookieMoQty, setCookieMoQty]=useState(0)
const[cookieMoTotal, setCookieMoTotal]=useState(0)


const[tiramisuQty, setTiramisuQty]=useState(0)
const[tiramisuTotal, setTiramisuTotal]=useState(0)


useEffect(() => {
setdlvdates(Ddates)
setpcpddates(Pdates)
}, [setdlvdates, setpcpddates, Pdates, Ddates]);


const orderCakes =[]

function handleOrder() {

const chocolate={"item":`Chocolate cake-${chocolateSize}`,"quantity":chocolatequantity, "addFrosty":`${chFrostyOption}`, "addToppings":chToppings, "sweetener":chSweetener, "total": chocolateTotal }
const carrot={"item":`Carrot cake-${carrotSize}`,"quantity":carrotquantity, "addFrosty":`${carrFrostyOption}`, "addToppings":carrToppings, "sweetener":carrSweetener,"total": carrotTotal }
const banana={"item":`Banana cake-${bananaSize}`,"quantity":bananaquantity, "addFrosty":`${banFrostyOption}`, "addToppings":banToppings, "sweetener":banSweetener, "total": bananaTotal }
const pineapple={"item":`Pineapple cake-${pineSize}`,"quantity":pinequantity, "addFrosty":`${pineFrostyOption}`, "addToppings":pineToppings, "sweetener":pineSweetener, "total": pineTotal }
const vanilla={"item":`Vanilla cake-${vanSize}`,"quantity":vanquantity, "addFrosty":`${vanFrostyOption}`, "addToppings":vanToppings, "sweetener":vanSweetener, "total": vanTotal }
const alcookie={"item":`Almonds cookie`,"quantity":cookieAlmQty, "addFrosty":"N/A", "addToppings":[], "sweetener":"N/A", "total": cookieAlmTotal }
const choccookie={"item":`Chocolate cookie`,"quantity":cookieChocQty, "addFrosty":"N/A", "addToppings":[], "sweetener":"N/A", "total": cookieChocTotal }
const mochacookie={"item":`Mocha cookie`,"quantity":cookieMoQty, "addFrosty":"N/A", "addToppings":[], "sweetener":"N/A", "total": cookieMoTotal }
const tiramisudes={"item":`Tiramisu Keto cake `,"quantity":tiramisuQty, "addFrosty":"N/A", "addToppings":[], "sweetener":"N/A", "total": tiramisuTotal }

if(chocolateTotal!==0){
orderCakes.push(chocolate)
}
if(carrotTotal!==0){
orderCakes.push(carrot)
}
if(bananaTotal!==0){
orderCakes.push(banana)
}
if(pineTotal!==0){
orderCakes.push(pineapple)
}
if(vanTotal!==0){
orderCakes.push(vanilla)
}
if(cookieAlmTotal!==0){
orderCakes.push(alcookie)
}
if(cookieChocTotal!==0){
orderCakes.push(choccookie)
}
if(cookieMoTotal!==0){
orderCakes.push(mochacookie)
}

if(tiramisuTotal!==0){
orderCakes.push(tiramisudes)
}

let orderCakesString = orderCakes.map(cake => `${cake.item} x${cake.quantity} - ${cake.total}`).join(', ');


if (orderCakesString.length > 110) {
orderCakesString = orderCakesString.substring(0, 110);
}

setorder(orderCakes);
setOrderDescription(orderCakesString)
setSubtotal(Total);
setisOrder(true);
navigate('/Order')
};

function handleEmptycart(){
setChocolateTotal(0) 
setChocolateSize('')
setChocolateQuantity(0)

setBananaTotal(0)
setBananaSize('')
setBananaQuantity(0)

setCarrotTotal(0) 
setCarrotSize('') 
setCarrotQuantity(0)

setPineTotal(0)
setPineSize('')
setPineQuantity(0)

setVanTotal(0)
setVanSize('') 
setVanQuantity(0)

setTotal(0)

setChFrostyOption('');
setChToppings([]);

setBanFrostyOption('');
setBanToppings([]);

setCarrFrostyOption('');
setCarrToppings([]);

setPineFrostyOption('');
setPineToppings([]);

setVanFrostyOption('');
setVanToppings([]);

setChSweetener('Coconut Sugar');

setBanSweetener('Coconut Sugar');

setCarrSweetener('Coconut Sugar');

setPineSweetener('Coconut Sugar');

setVanSweetener('Coconut Sugar');

setCookieAlmQty(0)
setCookieAlmTotal(0)
setCookieChocQty(0)
setCookieChocTotal(0)
setCookieMoQty(0)
setCookieMoTotal(0)
setTiramisuQty(0)
setTiramisuTotal(0)
}

const [showCakes, setShowCakes] = useState(false);
function toggleShowCakes () {setShowCakes(!showCakes); if(showCookies===true){
setShowCookies(false)}; if(showDeserts===true){
setShowDeserts(false)}
};

const [showCookies, setShowCookies] = useState(false);
function toggleShowCookies() {setShowCookies(!showCookies); if(showCakes===true){
setShowCakes(false)}; if(showDeserts===true){
setShowDeserts(false)}

};

const [showDeserts, setShowDeserts] = useState(false);
function toggleShowDeserts() {setShowDeserts(!showDeserts); if(showCakes===true){
setShowCakes(false)}; if(showCookies===true){
setShowCookies(false)};

};


if(loading4){
return(<div className='homemin'>loading...</div>)
}
if(loading5){
return(<div className='homemin'>loading...</div>)
}
return (
<div className='home'>

  <Canonical url="https://www.bitelandia.com/cakes_desserts_main" />

  <div className='watsaplogolink'>
    <a style={{cursor:'pointer'}} href='https://wa.me/16478718533' target='_blank' rel='noreferrer' ><img style={{width:"30px",verticalAlign:'middle', cursor:'pointer'}} src={imagews} alt='whatsapp icon'></img></a>
  </div>

  <h1 className='opening'>Indulge in our delicious wholesome preparations</h1>

  <div className='cakescontent'>
    <div className='newingredients'>
      <Element name="Cakes">
        <h1>
        Bitelandia’s Cakes
        </h1>
      </Element>
    </div>
    <div className='divparopening'>
      <div className='paropening'>
        <div>
          <p style={{ textAlign: 'justify', marginRight:"5px", fontWeight: "normal" }}>
          Made with natural fruits and <span style={{ fontWeight:"bolder" }}>GLUTEN-FREE</span> flour, our cakes can be customized with 
          optional toppings.We use low-calorie sweeteners like coconut sugar and monk fruit. Flavors available: 
          {/* <span style={{ fontWeight:"bolder", background:"orange" }}>Carrot</span>, 
          <span style={{ fontWeight:"bolder", background:" rgb(90, 73, 17)", color:"white" }}>Chocolate</span>, 
          <span style={{ fontWeight:"bolder", background:" rgb(251, 239, 200)" }}>Vanilla</span>, 
          <span style={{ fontWeight:"bolder", background:"rgb(237, 203, 33)" } }>Pineapple</span> and 
          <span style={{ fontWeight:"bolder", background:"rgb(224, 206, 116)"}}>Banana</span>. */}
          Carrot, 
          Chocolate, 
          Vanilla, 
          Pineapple and 
          Banana.
          <span>      
            <ScrollLink
          className="orderlink"
          style={{ cursor: 'pointer'}}
          to="Order"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          onClick={() => toggleShowCakes()}
          >
          (Order Now)
          </ScrollLink>&#x1f6d2;</span></p>
        </div>
        <div>
          <img className="cartoon" src={image4} alt="logo"></img>
        </div>
      </div>
    </div>
    <Cakes/>
  </div>

  <div className='cakescontent'>
    <div className='newingredients'>
      <Element name="Cookies">
        <h1>
        Bitelandia’s Cookies
        </h1>
      </Element>
    </div>
    <div className='divparopening'>
      <div className='paropening'>
        <div>
          <p style={{ textAlign: 'justify', marginRight:"5px", fontWeight: "normal" }}>
          Cookies prepared with rich  <span style={{ fontWeight:"bolder" }}>ALMOND</span> and <span style={{ fontWeight:"bolder" }}>OAT</span> flours,  No regular flour , just pure, wholesome goodness. Flavors available: Almonds, Chocolate, and Mocha.<span><ScrollLink
          className="orderlink"
          style={{ cursor: 'pointer'}}
          to="Order"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          onClick={() => toggleShowCookies()}
          >
          (Order Now)
          </ScrollLink>&#x1f6d2;</span>
          </p>
        </div>
      </div>
    </div>
    <Cookies/>
  </div>

  <div className='cakescontent'>
    <div className='newingredients'>
      <Element name="Deserts">
        <h1>
        Tiramisu Keto Cake
        </h1>
      </Element>
    </div>
    <div className='divparopening'>
      <div className='paropening'>
        <div>
          <p style={{ textAlign: 'justify', marginRight:"5px", fontWeight: "normal" }}>
          Introducing our Tiramisu dessert. This classic treat is irresistibly delicious and worth every bite<span><ScrollLink
          className="orderlink"
          style={{ cursor: 'pointer'}}
          to="Order"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          onClick={() => toggleShowDeserts()}
          >
          (Order Now)
          </ScrollLink>&#x1f6d2;</span>
          </p>
        </div>
      </div>
    </div>
    <Tiramisu/>
  </div>


  {(Pdates==="0")&&(Ddates==="0")? (
  <div className='cakescontent'>
    <div className='newingredients'> 
      <Element name="Order">
        <h1>
        <span>&#x1f6d2;</span> Order
        </h1>
      </Element>
    </div>
    <div className='divparopening'>
      <div className='paropening'>
        <p style={{ textAlign: 'justify', marginRight:"5px", fontWeight:"normal" }}>
          Orders will be available soon
        </p>
      </div>
    </div>
  </div>
  ) : (
  <div className='cakescontent'> 
    <div className='newingredients'>
      <Element name="Order">
        <h1>
          <span>&#x1f6d2;</span> Order
        </h1>
      </Element>
    </div>
    <div className='divparopening'>
      <div className='paropening'>
        <p style={{ textAlign: 'justify', marginRight:"5px", fontWeight:"normal" }}>
          We deliver to Oakville, Mississauga, Milton, and Burlington, ON. Pick-up is available. Pay via PayPal, debit, or credit card. The 'Checkout' button will be displayed when selecting a product. Click it to proceed with payment. <br></br><br></br>
        </p>
      </div>
    </div>
    <div>
      <div className={showCakes||showCookies||showDeserts?'Order2':'Order'}> 

        <div className='Checkout'>      
          {(Total > 1 && isOrder === false) ? (
          <div className='btsorder'>      
            <button
            className="placeorderbtn" style={{ cursor: 'pointer' }}
            onClick={handleOrder}>
              Checkout<span>&#x1f6d2;</span>
            </button>
            <button
            className="placeorderbtn" style={{ cursor: 'pointer' }}
            onClick={handleEmptycart}>
              empty cart
            </button>
            <h3 className='homeTotal'>Total:</h3>
            <h2 className='homeTotal'>${Total}</h2>
          </div>
          ) : (
          <div>
          </div>
          )}  
        </div>

        {/* <div className='headerorder'>
          <h3 className='homeTotal'>Total:</h3>
        </div>

        <div className='headerorder'>
          <h2 className='homeTotal'>${Total}</h2>
        </div> */}

        <div style={{ cursor: 'pointer'}} className='headerorder' onClick={toggleShowCakes}>
          <h3>ORDER CAKES</h3>
        </div>
        {showCakes&&
        <div>{cakeson===true||cakeson===undefined?(
          <div>
            <div className='paropening'>
              <p style={{ textAlign: 'justify', marginRight:"5px", fontWeight:"normal" }}>
              Order more, pay less per item.
              </p>
            </div>
            <div className='cakeflex'>
              <div className='caketitlediv'>
                <Chocolate
                isOrder={isOrder}
                chFrostyOption={chFrostyOption}
                setChFrostyOption={setChFrostyOption}
                chToppings={chToppings}
                setChToppings={setChToppings}
                chocolateTotal={chocolateTotal}
                setChocolateTotal={setChocolateTotal}
                chocolateSize={chocolateSize}
                setChocolateSize={setChocolateSize}
                chocolatequantity={chocolatequantity}
                setChocolateQuantity={setChocolateQuantity}
                carrotTotal={carrotTotal}
                bananaTotal={bananaTotal}
                pineTotal={pineTotal} 
                chSweetener={chSweetener} 
                setChSweetener={setChSweetener}
                setTotal={setTotal}
                vanTotal={vanTotal}
                cookieAlmTotal={cookieAlmTotal}
                cookieChocTotal={cookieChocTotal}
                cookieMoTotal={cookieMoTotal}
                tiramisuTotal={tiramisuTotal}
                />
              </div>
              <div className='caketitlediv'>
                <Banana
                isOrder={isOrder}
                bananaTotal={bananaTotal}
                setBananaTotal={setBananaTotal}
                bananaSize={bananaSize} 
                setBananaSize={setBananaSize}
                bananaquantity={bananaquantity} 
                setBananaQuantity={setBananaQuantity}
                banFrostyOption={banFrostyOption}
                setBanFrostyOption={setBanFrostyOption}
                banToppings={banToppings}
                setBanToppings={setBanToppings}
                carrotTotal={carrotTotal}
                chocolateTotal={chocolateTotal}
                pineTotal={pineTotal} al
                banSweetener={banSweetener} 
                setBanSweetener={setBanSweetener}
                setTotal={setTotal}
                vanTotal={vanTotal}
                cookieAlmTotal={cookieAlmTotal}
                cookieChocTotal={cookieChocTotal}
                cookieMoTotal={cookieMoTotal}
                tiramisuTotal={tiramisuTotal}
                />
              </div>
              <div className='caketitlediv'>
                <Carrot
                isOrder={isOrder}
                carrotTotal={carrotTotal} 
                setCarrotTotal={setCarrotTotal}
                carrotSize={carrotSize} 
                setCarrotSize={setCarrotSize}
                carrotquantity={carrotquantity} 
                setCarrotQuantity={setCarrotQuantity}
                carrFrostyOption={carrFrostyOption} 
                setCarrFrostyOption={setCarrFrostyOption}
                carrToppings={carrToppings} 
                setCarrToppings={setCarrToppings}
                chocolateTotal={chocolateTotal}
                bananaTotal={bananaTotal}
                pineTotal={pineTotal} 
                carrSweetener={carrSweetener} 
                setCarrSweetener={setCarrSweetener}
                setTotal={setTotal}
                vanTotal={vanTotal}
                cookieAlmTotal={cookieAlmTotal}
                cookieChocTotal={cookieChocTotal}
                cookieMoTotal={cookieMoTotal}
                tiramisuTotal={tiramisuTotal}
                />  
              </div>
              <div className='caketitlediv'>
                <Pineapple
                isOrder={isOrder}
                pineTotal={pineTotal} 
                setPineTotal={setPineTotal}
                pineSize={pineSize} 
                setPineSize={setPineSize}
                pinequantity={pinequantity} 
                setPineQuantity={setPineQuantity}
                pineFrostyOption={pineFrostyOption} 
                setPineFrostyOption={setPineFrostyOption}
                pineToppings={pineToppings} 
                setPineToppings={setPineToppings}
                chocolateTotal={chocolateTotal}
                bananaTotal={bananaTotal}
                carrotTotal={carrotTotal} 
                pineSweetener={pineSweetener} 
                setPineSweetener={setPineSweetener}
                setTotal={setTotal}
                vanTotal={vanTotal}
                cookieChocTotal={cookieChocTotal}
                cookieAlmTotal={cookieAlmTotal}
                cookieMoTotal={cookieMoTotal}
                tiramisuTotal={tiramisuTotal}
                />  
              </div>
              <div className='caketitlediv'>
                <Vanilla
                isOrder={isOrder}
                vanTotal={vanTotal} 
                setVanTotal={setVanTotal}
                vanSize={vanSize} 
                setVanSize={setVanSize}
                cookieAlmTotal={cookieAlmTotal}
                vanquantity={vanquantity} 
                setVanQuantity={setVanQuantity}
                vanFrostyOption={vanFrostyOption} 
                setVanFrostyOption={setVanFrostyOption}
                vanToppings={vanToppings} 
                setVanToppings={setVanToppings}
                chocolateTotal={chocolateTotal}
                bananaTotal={bananaTotal}
                carrotTotal={carrotTotal} 
                pineTotal={pineTotal} 
                vanSweetener={vanSweetener} 
                setVanSweetener={setVanSweetener}
                cookieChocTotal={cookieChocTotal}
                cookieMoTotal={cookieMoTotal}
                tiramisuTotal={tiramisuTotal}
                setTotal={setTotal}
                />  
              </div>
            </div>
          </div>):(
          <div className='divparopening'>
            <div className='paropening'>
              <p style={{ textAlign: 'justify', marginRight:"5px", fontWeight:"normal" }}>
              You have a pending order for Tamal. Please complete it to place an order for Cakes. Thank you.
              </p>
            </div>
          </div>
          )}
        </div>
        }
        <div style={{ cursor: 'pointer'}} className='headerorder' onClick={toggleShowCookies}>
          <h3>ORDER COOKIES</h3>
        </div>
        {showCookies&&(cakeson===true||cakeson===undefined)?( 
        <div className='paropening'>
          <p style={{ textAlign: 'justify', marginRight:"5px", fontWeight:"normal" }}> Order more, pay less per item</p>
        </div>):("")}
        {showCookies&&
        <div>
          {(cakeson===true||cakeson===undefined)?(
          <div className='cakeflex'>
            <div className='caketitlediv'>
              <Cookiealmonds
              isOrder={isOrder}
              cookieAlmQty={cookieAlmQty}
              setCookieAlmQty={setCookieAlmQty}
              setCookieAlmTotal={setCookieAlmTotal}
              cookieAlmTotal={cookieAlmTotal}
              cookieChocTotal={cookieChocTotal}
              vanTotal={vanTotal}
              chocolateTotal={chocolateTotal}
              carrotTotal={carrotTotal}
              bananaTotal={bananaTotal}
              cookieMoTotal={cookieMoTotal}
              pineTotal={pineTotal} 
              tiramisuTotal={tiramisuTotal}
              setTotal={setTotal}
              />
            </div>
            <div className='caketitlediv'>
              <Cookiechoco
              isOrder={isOrder}
              cookieChocQty={cookieChocQty}
              setCookieChocQty={setCookieChocQty}
              setCookieChocTotal={setCookieChocTotal}
              cookieChocTotal={cookieChocTotal}
              cookieAlmTotal={cookieAlmTotal}
              cookieMoTotal={cookieMoTotal}
              vanTotal={vanTotal}
              chocolateTotal={chocolateTotal}
              carrotTotal={carrotTotal}
              bananaTotal={bananaTotal}
              pineTotal={pineTotal}
              tiramisuTotal={tiramisuTotal} 
              setTotal={setTotal}
              />
            </div>
            <div className='caketitlediv'>
              <Cookiemocha
              isOrder={isOrder}
              cookieMoQty={cookieMoQty}
              setCookieMoQty={setCookieMoQty}
              setCookieMoTotal={setCookieMoTotal}
              cookieMoTotal={cookieMoTotal}
              cookieChocTotal={cookieChocTotal}
              cookieAlmTotal={cookieAlmTotal}
              vanTotal={vanTotal}
              chocolateTotal={chocolateTotal}
              carrotTotal={carrotTotal}
              bananaTotal={bananaTotal}
              pineTotal={pineTotal} 
              tiramisuTotal={tiramisuTotal}
              setTotal={setTotal}
              />
            </div>  
          </div>):(
          <div className='divparopening'>
            <div className='paropening'>
              <p style={{ textAlign: 'justify', marginRight:"5px", fontWeight:"normal" }}>
                You have a pending order for Tamal. Please complete it to place an order for Cookies. Thank you.
              </p>
            </div>
          </div>)}
        </div>}
        <div style={{ cursor: 'pointer'}} className='headerorder' onClick={toggleShowDeserts}>
          <h3>ORDER DESERTS</h3>
        </div>
          {showDeserts&&
        <div>
          {cakeson===true||cakeson===undefined?(
          <div>
            <div className='paropening'>
              <p style={{ textAlign: 'justify', marginRight:"5px", fontWeight:"normal" }}>
              Each Tiramisu weighs 650 grams. Order more, pay less per item.
              </p>
            </div>
            <div className='cakeflex'>
              <div className='caketitlediv'>
                <Tiramisuorder
                isOrder={isOrder}
                tiramisuQty={tiramisuQty}
                setTiramisuQty={setTiramisuQty}
                setTiramisuTotal={setTiramisuTotal}
                tiramisuTotal={tiramisuTotal}
                cookieAlmTotal={cookieAlmTotal}
                cookieChocTotal={cookieChocTotal}
                vanTotal={vanTotal}
                chocolateTotal={chocolateTotal}
                carrotTotal={carrotTotal}
                bananaTotal={bananaTotal}
                cookieMoTotal={cookieMoTotal}
                pineTotal={pineTotal}
                setTotal={setTotal}
                />
              </div>
            </div>
          </div>):(
          <div className='divparopening'>
            <div className='paropening'>
              <p style={{ textAlign: 'justify', marginRight:"5px", fontWeight:"normal" }}>
              You have a pending order for Tamal. Please complete it to place an order for Tiramisu. Thank you.
              </p>
            </div>
          </div>)}
        </div>}


      </div>
    </div>
  </div>
  )}
</div>
);
};

export default Home;