import React from 'react';
import { useState} from 'react';
import { useEffect} from 'react'
import image7 from '../../components/img/pineappleoval.png'
import jsonData from '../../data.json';
import toppingsOptions from '../../utils/ToppingsData'
import {FrostyOptsPine} from '../../utils/FrostyData'
import {pineSweetOptions} from '../../utils/SweetenerData'


const Pineapple = ({
  isOrder,
  pineTotal,
  setPineTotal,
  pineSize, 
  setPineSize,
  pinequantity,
  setPineQuantity,
  pineFrostyOption,
  setPineFrostyOption,
  pineToppings,
  setPineToppings,
  chocolateTotal,
  bananaTotal,
  carrotTotal, 
  pineSweetener,
  setPineSweetener,
  setTotal,
  vanTotal,
  cookieChocTotal,
  cookieMoTotal,
  tiramisuTotal,
  cookieAlmTotal,
  }) => 
{
const [pineToppmpbutton, setTopppinempbutton] = useState(false);
const [pineHVtoppings, setPineHVtoppings] = useState("hiddenoptions");
const [pineFrosmpbutton, setFrospinempbutton] = useState(false);
const [pineHVfrosting, setPineHVfrosting] = useState("hiddenoptions");


useEffect(() => {
if (pineToppmpbutton===true) {
setPineHVtoppings("visibleoptions")
}else{
setPineHVtoppings("hiddenoptions")
}


}, [pineToppmpbutton, setPineHVtoppings]);

const handlePineToppVis = () => {

if (pineToppmpbutton===false) {
setTopppinempbutton(true)

}else{
setTopppinempbutton(false)
}

}


useEffect(() => {
if (pineFrosmpbutton===true) {
setPineHVfrosting("visibleoptions")
}else{
setPineHVfrosting("hiddenoptions")
}


}, [pineFrosmpbutton, setPineHVfrosting]);

const handlePineFrosVis = () => {

if (pineFrosmpbutton===false) {
setFrospinempbutton(true)

}else{
setFrospinempbutton(false)
}

}

  const handlePineFrOpt = (event) => {
    const value = event.target.value;
    if (pineFrostyOption === value) {
      setPineFrostyOption("");
      pineTotalcalc(pinequantity,pineSize, '', pineToppings, pineSweetener)
    } else {
      setPineFrostyOption(value);
      pineTotalcalc(pinequantity,pineSize, value, pineToppings, pineSweetener)
    }
  };
  const handlePineSwOpt = (event) => {
    const value = event.target.value;
    if(pineSweetener === value){
      if (value === "Coconut Sugar") {
        setPineSweetener("Monk Fruit");
        pineTotalcalc(pinequantity, pineSize, pineFrostyOption, pineToppings, "Monk Fruit")
      } else if(value === "Monk Fruit"){
        setPineSweetener("Coconut Sugar");
        pineTotalcalc(pinequantity,pineSize, pineFrostyOption, pineToppings, "Coconut Sugar")
      }
    }else{
      setPineSweetener(value);
      pineTotalcalc(pinequantity,pineSize, pineFrostyOption, pineToppings, value)
    }
    }
  

  const handlePineToppings = (event) => {
    const optionValue = event.target.value;

    if (pineToppings.includes(optionValue)) {
      setPineToppings(pineToppings.filter((option) => option !== optionValue));
      pineTotalcalc(pinequantity,pineSize, pineFrostyOption, pineToppings.filter((option) => option !== optionValue), pineSweetener)
    } else if (pineToppings.length < 2) {
      setPineToppings([...pineToppings, optionValue]);
      pineTotalcalc(pinequantity,pineSize, pineFrostyOption, [...pineToppings, optionValue], pineSweetener)
    }
  };

  function handlePineChange(event) {
    setPineSize(event.target.value);
    pineTotalcalc(pinequantity, event.target.value, pineFrostyOption, pineToppings, pineSweetener)
  }

  function handlePineIncrement() {
    setPineQuantity(pinequantity + 1);
    pineTotalcalc((pinequantity + 1),pineSize, pineFrostyOption, pineToppings, pineSweetener)
  }

  function handlePineDecrement() {
    if (pinequantity=== 1) {
      setPineQuantity(0);
      pineTotalcalc(0,pineSize, pineFrostyOption, pineToppings, pineSweetener)
    }else if(pinequantity>1){
      setPineQuantity(pinequantity-1);
      pineTotalcalc((pinequantity-1),pineSize, pineFrostyOption, pineToppings, pineSweetener)
    }
  }

  function pineTotalcalc(b,c,d,e,f) {

    const pineFrosty = d
    var addPineFrosty = 0
    const pnToppings = e
    var addPineToppings = 0
    const pineSwtner = f
    var addPineSwtner = 0

    if(c==="1000 grams"){
      if(pineFrosty){

        var optionset1=FrostyOptsPine.find((Frosty) => Frosty.value === pineFrosty)
        if(optionset1){
          var price1 = optionset1.price.find((price)=> price.cake === "1000 grams")
          if(price1){
            addPineFrosty=price1.priceTag
          }
        }
        
      }
      if(pineSwtner){

        var optionset2=pineSweetOptions.find((sweet) => sweet.value === pineSwtner)
        if(optionset2){
          var price2 = optionset2.price.find((price)=> price.cake === "1000 grams")
          if(price2){
            addPineSwtner=price2.priceTag
          }
        }
        
      }
      if(pnToppings){
            var optionsetarr1=pnToppings.map((topping) => toppingsOptions.find((choice)=> choice.value === topping))
        if(optionsetarr1){
          var pricearr1 = optionsetarr1.map((option) => (option.price.find((price)=> price.cake === "1000 grams")).priceTag)
          if(pricearr1){
            for (let index = 0; index < pricearr1.length; index++) {
              var element1 = pricearr1[index];
              addPineToppings+=element1
            }
          }
        }
       
      }
      if(b===1){
        const pineapple = (jsonData.pineapple.s1000+(addPineFrosty*1)+(addPineToppings*1)+(addPineSwtner*1)).toFixed(2)
        setPineTotal(pineapple)
        setTotal(((carrotTotal*1)+(pineapple*1)+(chocolateTotal*1)+(bananaTotal*1)+(vanTotal*1)+(cookieAlmTotal*1)+(cookieChocTotal*1)+(cookieMoTotal*1)+(tiramisuTotal*1)).toFixed(2))
      }else if(b>1){
        const pineapple = (((jsonData.pineapple.s1000am+(addPineFrosty*1)+(addPineToppings*1)+(addPineSwtner*1))*b)+jsonData.pineapple.s1000ab).toFixed(2)
        setPineTotal(pineapple)
        setTotal(((carrotTotal*1)+(pineapple*1)+(chocolateTotal*1)+(bananaTotal*1)+(vanTotal*1)+(cookieAlmTotal*1)+(cookieChocTotal*1)+(cookieMoTotal*1)+(tiramisuTotal*1)).toFixed(2))
      }else{
        const pineapple =0
        setPineTotal(0)
        setTotal(((carrotTotal*1)+(pineapple*1)+(chocolateTotal*1)+(bananaTotal*1)+(vanTotal*1)+(cookieAlmTotal*1)+(cookieChocTotal*1)+(cookieMoTotal*1)+(tiramisuTotal*1)).toFixed(2))
      }
    }else if(c==="650 grams"){
      if(pineFrosty){

        var optionset=FrostyOptsPine.find((Frosty) => Frosty.value === pineFrosty)
        if(optionset){
          var price = optionset.price.find((price)=> price.cake === "650 grams")
          if(price){
            addPineFrosty=price.priceTag
          }
        }
        
      }
      if(pineSwtner){

        var optionset3=pineSweetOptions.find((sweet) => sweet.value === pineSwtner)
        if(optionset3){
          var price3 = optionset3.price.find((price)=> price.cake === "650 grams")
          if(price3){
            addPineSwtner=price3.priceTag
          }
        }
        
      }
      if(pnToppings){
            var optionsetarr=pnToppings.map((topping) => toppingsOptions.find((choice)=> choice.value === topping))
        if(optionsetarr){
          var pricearr = optionsetarr.map((option) => (option.price.find((price)=> price.cake === "650 grams")).priceTag)
          if(pricearr){
            for (let index = 0; index < pricearr.length; index++) {
              var element = pricearr[index];
              addPineToppings+=element
            }
          }
        }
       
      }
      if(b===1){
        const pineapple = (jsonData.pineapple.s650+(addPineFrosty*1)+(addPineToppings*1)+(addPineSwtner*1)).toFixed(2)
        setPineTotal(pineapple)
        setTotal(((carrotTotal*1)+(pineapple*1)+(chocolateTotal*1)+(bananaTotal*1)+(vanTotal*1)+(cookieAlmTotal*1)+(cookieChocTotal*1)+(cookieMoTotal*1)+(tiramisuTotal*1)).toFixed(2))
      }else if(b>1){
        const pineapple = (((jsonData.pineapple.s650am+(addPineFrosty*1)+(addPineToppings*1)+(addPineSwtner*1))*b)+jsonData.pineapple.s650ab).toFixed(2)
        setPineTotal(pineapple)
        setTotal(((carrotTotal*1)+(pineapple*1)+(chocolateTotal*1)+(bananaTotal*1)+(vanTotal*1)+(cookieAlmTotal*1)+(cookieChocTotal*1)+(cookieMoTotal*1)+(tiramisuTotal*1)).toFixed(2))
      }else{
        const pineapple =0
        setPineTotal(0)
        setTotal(((carrotTotal*1)+(pineapple*1)+(chocolateTotal*1)+(bananaTotal*1)+(vanTotal*1)+(cookieAlmTotal*1)+(cookieChocTotal*1)+(cookieMoTotal*1)+(tiramisuTotal*1)).toFixed(2))
      }
    }else{
      const pineapple= 0
      setPineTotal(pineapple)
      setTotal(((carrotTotal*1)+(pineapple*1)+(chocolateTotal*1)+(bananaTotal*1)+(vanTotal*1)+(cookieAlmTotal*1)+(cookieChocTotal*1)+(cookieMoTotal*1)+(tiramisuTotal*1)).toFixed(2))
    }
  }


  return (
       

          <div>
            <div className='photoOrder'>
            <div className='cakesNameTotal'>
              <h3 className='cakeTitle'>Pineapple:</h3>
              <h3>${pineTotal}</h3>
            </div>
            <img className='photosOrder'src={image7} alt="pineapple cake"></img>

            </div>
            
            {isOrder===true?(
            <div>
              <h1>Pineapple Size:{pineSize}</h1>
              <h1>Quantity:{pinequantity}</h1>
              <h1>Frosting:{pineFrostyOption}</h1>
              <h1>Toppings:{pineToppings}</h1>
            </div>):
            (<div className='displayit'>
              <div>
                <label htmlFor="size-select-pineapple"><span>Select cake size:</span></label>
                <select id="size-select-pineapple" value={pineSize} onChange={handlePineChange}>
                  <option value="">Select size</option> {/* initial default option */}
                  <option value="1000 grams">1000 grams</option>
                  <option value="650 grams">650 grams</option>
                </select>
              </div>
              <div  className='btdiv'>
                <button className="btplusminus" style={{ cursor: 'pointer'}}  onClick={handlePineDecrement}>-</button>
                  <h4 className="qty">{pinequantity}</h4>
                <button className="btplusminus" style={{ cursor: 'pointer'}}  onClick={handlePineIncrement}>+</button>
              </div>
            </div>)}
            {!isOrder && (
            <div>
                     <button className='pmbutton' onClick={handlePineToppVis}>
              <h3 style={{ textDecoration: 'underline' }}>Add Toppings</h3>
                  </button>
              {toppingsOptions.map((option) => (
              <div key={option.value} className={pineHVtoppings}> 
                <input
                type="checkbox"
                value={option.value}
                checked={pineToppings.includes(option.value)}
                onChange={handlePineToppings}
                disabled={
                pineToppings.length === 2 && !pineToppings.includes(option.value)
                }
                />
                <label>{option.label}-$
                {
            pineSize &&
            option.price.find((price) => price.cake === pineSize)
              .priceTag
          }
      
                </label>
              </div>
              ))}
            </div>)}
            {!isOrder && (
            <div>
              <button className='pmbutton'  onClick={handlePineFrosVis}>
              <h3 style={{ textDecoration: 'underline' }}>Add Frosting</h3>
              </button>
              {FrostyOptsPine.map((option) => (
              <div key={option.value} className={pineHVfrosting}>
                <input
                type="checkbox"
                value={option.value}
                checked={pineFrostyOption === option.value}
                onChange={handlePineFrOpt}
                disabled={pineFrostyOption !== '' && pineFrostyOption !== option.value}
                />
                <label>
                {option.label}-$
                {
            pineSize &&
            option.price.find((price) => price.cake === pineSize)
              .priceTag
          }
                </label> 
              </div>
              ))}

            </div>
            )}
            {!isOrder && (
            <div>
              <h3>Select sweetener</h3>
              {pineSweetOptions.map((option) => (
              <div key={option.value}>
                <input
                type="checkbox"
                value={option.value}
                checked={pineSweetener === option.value}
                onChange={handlePineSwOpt}
                
                />
                <label>
                {option.label}-$
                {
            pineSize &&
            option.price.find((price) => price.cake === pineSize)
              .priceTag
          }
                </label> 
              </div>
              ))}

            </div>
            )}
           
           
           

          </div>



  );
};

export default Pineapple;