import React from 'react';
import image7 from '../../components/img/eclipsecarr.png'
import jsonData from '../../data.json';
import toppingsOptions from '../../utils/ToppingsData'
import {FrostyOptsBan} from '../../utils/FrostyData'
import {banSweetOptions} from '../../utils/SweetenerData'
import { useState} from 'react';
import { useEffect} from 'react'

const Banana = ({
isOrder,
bananaTotal, 
setBananaTotal,
bananaSize, 
setBananaSize,
bananaquantity, 
setBananaQuantity,
banFrostyOption, 
setBanFrostyOption,
banToppings, 
setBanToppings,
carrotTotal,
chocolateTotal,
banSweetener, 
setBanSweetener, 
setTotal,
pineTotal,
cookieChocTotal,
vanTotal,
cookieMoTotal,
tiramisuTotal,
cookieAlmTotal,


}) => 
{

const [banToppmpbutton, setToppbanmpbutton] = useState(false);
const [banHVtoppings, setBanHVtoppings] = useState("hiddenoptions");
const [banFrosmpbutton, setFrosbanmpbutton] = useState(false);
const [banHVfrosting, setBanHVfrosting] = useState("hiddenoptions");

useEffect(() => {
  if (banToppmpbutton===true) {
    setBanHVtoppings("visibleoptions")
  }else{
    setBanHVtoppings("hiddenoptions")
  }
}, [banToppmpbutton, setBanHVtoppings]);

const handleBanToppVis = () => {
  if (banToppmpbutton===false) {
    setToppbanmpbutton(true)
  }else{
    setToppbanmpbutton(false)
  }
}

useEffect(() => {
  if (banFrosmpbutton===true) {
  setBanHVfrosting("visibleoptions")
  }else{
  setBanHVfrosting("hiddenoptions")
  }
}, [banFrosmpbutton, setBanHVfrosting]);

const handleBanFrosVis = () => {
  if (banFrosmpbutton===false) {
    setFrosbanmpbutton(true)
  }else{
    setFrosbanmpbutton(false)
  }
}

const handleBanFrOpt = (event) => {
  const value = event.target.value;
  if (banFrostyOption === value) {
    setBanFrostyOption("");
    bananaTotalcalc(bananaquantity,bananaSize, '', banToppings, banSweetener)
  } else {
    setBanFrostyOption(value);
    bananaTotalcalc(bananaquantity,bananaSize, value, banToppings, banSweetener)
  }
};

const handleBanSwOpt = (event) => {
  const value = event.target.value;
  if(banSweetener === value){
    if (value === "Coconut Sugar") {
      setBanSweetener("Monk Fruit");
      bananaTotalcalc(bananaquantity,bananaSize, banFrostyOption, banToppings, "Monk Fruit")
    } else if(value === "Monk Fruit"){
      setBanSweetener("Coconut Sugar");
      bananaTotalcalc(bananaquantity,bananaSize, banFrostyOption, banToppings, "Coconut Sugar")
    }
  }else{
    setBanSweetener(value);
    bananaTotalcalc(bananaquantity,bananaSize, banFrostyOption, banToppings, value)
  }
}

const handleBanToppings = (event) => {
  const optionValue = event.target.value;
  if (banToppings.includes(optionValue)) {
    setBanToppings(banToppings.filter((option) => option !== optionValue));
    bananaTotalcalc(bananaquantity,bananaSize, banFrostyOption, banToppings.filter((option) => option !== optionValue), banSweetener)
  } else if (banToppings.length < 2) {
    setBanToppings([...banToppings, optionValue]);
    bananaTotalcalc(bananaquantity,bananaSize, banFrostyOption, [...banToppings, optionValue], banSweetener)
  }
};

function handleBananaChange(event) {
  setBananaSize(event.target.value);
  bananaTotalcalc(bananaquantity, event.target.value, banFrostyOption, banToppings, banSweetener)
}

function handleBananaIncrement() {
  setBananaQuantity(bananaquantity + 1);
  bananaTotalcalc((bananaquantity + 1),bananaSize, banFrostyOption, banToppings, banSweetener)
}

function handleBananaDecrement() {
  if (bananaquantity=== 1) {
    setBananaQuantity(0);
    bananaTotalcalc(0,bananaSize, banFrostyOption, banToppings, banSweetener)
  }else if(bananaquantity>1){
    setBananaQuantity(bananaquantity-1);
    bananaTotalcalc((bananaquantity-1),bananaSize, banFrostyOption, banToppings, banSweetener)
  }
}

function bananaTotalcalc(b,c,d,e,f) {

const banFrosty = d
var addBanFrosty = 0
const bnToppings = e
var addBanToppings = 0
const banSwtner = f
var addBanSwtner = 0

if(c==="1000 grams"){
  if(banFrosty){
    var optionset1=FrostyOptsBan.find((Frosty) => Frosty.value === banFrosty)
    if(optionset1){
      var price1 = optionset1.price.find((price)=> price.cake === "1000 grams")
      if(price1){
        addBanFrosty=price1.priceTag
      }
    }
  }

  if(banSwtner){
    var optionset2=banSweetOptions.find((sweet) => sweet.value === banSwtner)
    if(optionset2){
      var price2 = optionset2.price.find((price)=> price.cake === "1000 grams")
      if(price2){
        addBanSwtner=price2.priceTag
      }
    }
  }

  if(bnToppings){
    var optionsetarr1=bnToppings.map((topping) => toppingsOptions.find((choice)=> choice.value === topping))
    if(optionsetarr1){
      var pricearr1 = optionsetarr1.map((option) => (option.price.find((price)=> price.cake === "1000 grams")).priceTag)
      if(pricearr1){
        for (let index = 0; index < pricearr1.length; index++) {
          var element1 = pricearr1[index];
          addBanToppings+=element1
        }
      }
    }
  }

  if(b===1){
    const banana = (jsonData.banana.s1000+(addBanFrosty*1)+(addBanToppings*1)+(addBanSwtner*1)).toFixed(2)
    setBananaTotal(banana)
    setTotal(((carrotTotal*1)+(banana*1)+(cookieAlmTotal*1)+(chocolateTotal*1)+(pineTotal*1)+(vanTotal*1)+(cookieChocTotal*1)+(cookieMoTotal*1)+(tiramisuTotal*1)).toFixed(2))
  }else if(b>1){
    const banana = (((jsonData.banana.s1000am+(addBanFrosty*1)+(addBanToppings*1)+(addBanSwtner*1))*b)+jsonData.banana.s1000ab).toFixed(2)
    setBananaTotal(banana)
    setTotal(((carrotTotal*1)+(banana*1)+(chocolateTotal*1)+(pineTotal*1)+(vanTotal*1)+(cookieAlmTotal*1)+(cookieChocTotal*1)+(cookieMoTotal*1)+(tiramisuTotal*1)).toFixed(2))
  }else{
    const banana =0
    setBananaTotal(0)
    setTotal(((carrotTotal*1)+(banana*1)+(chocolateTotal*1)+(pineTotal*1)+(vanTotal*1)+(cookieAlmTotal*1)+(cookieChocTotal*1)+(cookieMoTotal*1)+(tiramisuTotal*1)).toFixed(2))
  }
}else if(c==="650 grams"){
  if(banFrosty){
    var optionset=FrostyOptsBan.find((Frosty) => Frosty.value === banFrosty)
    if(optionset){
      var price = optionset.price.find((price)=> price.cake === "650 grams")
      if(price){
        addBanFrosty=price.priceTag
      }
    }
  }
  if(banSwtner){
    var optionset3=banSweetOptions.find((sweet) => sweet.value === banSwtner)
    if(optionset3){
      var price3 = optionset3.price.find((price)=> price.cake === "650 grams")
      if(price3){
        addBanSwtner=price3.priceTag
      }
    }
  }
  if(bnToppings){
  var optionsetarr=bnToppings.map((topping) => toppingsOptions.find((choice)=> choice.value === topping))
  if(optionsetarr){
  var pricearr = optionsetarr.map((option) => (option.price.find((price)=> price.cake === "650 grams")).priceTag)
  if(pricearr){
  for (let index = 0; index < pricearr.length; index++) {
  var element = pricearr[index];
  addBanToppings+=element
  }
  }
  }

  }
  if(b===1){
  const banana = (jsonData.banana.s650+(addBanFrosty*1)+(addBanToppings*1)+(addBanSwtner*1)).toFixed(2)
  setBananaTotal(banana)
  setTotal(((carrotTotal*1)+(banana*1)+(chocolateTotal*1)+(pineTotal*1)+(vanTotal*1)+(cookieAlmTotal*1)+(cookieChocTotal*1)+(cookieMoTotal*1)+(tiramisuTotal*1)).toFixed(2))
  }else if(b>1){
  const banana = (((jsonData.banana.s650am+(addBanFrosty*1)+(addBanToppings*1)+(addBanSwtner*1))*b)+jsonData.banana.s650ab).toFixed(2)
  setBananaTotal(banana)
  setTotal(((carrotTotal*1)+(banana*1)+(chocolateTotal*1)+(pineTotal*1)+(vanTotal*1)+(cookieAlmTotal*1)+(cookieChocTotal*1)+(cookieMoTotal*1)+(tiramisuTotal*1)).toFixed(2))
  }else{
  const banana =0
  setBananaTotal(0)
  setTotal(((carrotTotal*1)+(banana*1)+(chocolateTotal*1)+(pineTotal*1)+(vanTotal*1)+(cookieAlmTotal*1)+(cookieChocTotal*1)+(cookieMoTotal*1)+(tiramisuTotal*1)).toFixed(2))
  }
}else{
const banana= 0
setBananaTotal(banana)
setTotal(((carrotTotal*1)+(banana*1)+(chocolateTotal*1)+(pineTotal*1)+(vanTotal*1)+(cookieAlmTotal*1)+(cookieChocTotal*1)+(cookieMoTotal*1)+(tiramisuTotal*1)).toFixed(2))
}
}


return (
<div>
<div className='photoOrder'>
<div className='cakesNameTotal'>
<h3 className='cakeTitle'>Banana:</h3>
<h3>${bananaTotal}</h3>
</div>
<img className='photosOrder'src={image7} alt="banana cake"></img>

</div>

{isOrder===true?(
<div>
<h1>Banana Size:{bananaSize}</h1>
<h1>Quantity:{bananaquantity}</h1>
<h1>Frosting:{banFrostyOption}</h1>
<h1>Toppings:{banToppings}</h1>
</div>):
(<div className='displayit'>
<div>
<label htmlFor="size-select-banana"><span>Select cake size:</span></label>
<select id="size-select-banana" value={bananaSize} onChange={handleBananaChange}>
<option value="">Select size</option> {/* initial default option */}
<option value="1000 grams">1000 grams</option>
<option value="650 grams">650 grams</option>
</select>
</div>
<div  className='btdiv'>
<button className="btplusminus" style={{ cursor: 'pointer'}}  onClick={handleBananaDecrement}>-</button>
<h4 className="qty">{bananaquantity}</h4>
<button className="btplusminus" style={{ cursor: 'pointer'}}  onClick={handleBananaIncrement}>+</button>
</div>
</div>)}
{!isOrder && (
<div>

<button className='pmbutton'  onClick={handleBanToppVis}>
<h3 style={{ textDecoration: 'underline' }}>Add Toppings</h3>
</button>


{toppingsOptions.map((option) => (
<div key={option.value} className={banHVtoppings}> 
<input
type="checkbox"
value={option.value}
checked={banToppings.includes(option.value)}
onChange={handleBanToppings}
disabled={
banToppings.length === 2 && !banToppings.includes(option.value)
}
/>
<label>{option.label}-$
{
bananaSize &&
option.price.find((price) => price.cake === bananaSize)
.priceTag
}

</label>
</div>
))}
</div>)}
{!isOrder && (
<div>

<button className='pmbutton' onClick={handleBanFrosVis}>
<h3 style={{ textDecoration: 'underline' }}>Add Frosting</h3>
</button>

{FrostyOptsBan.map((option) => (
<div key={option.value} className={banHVfrosting}>
<input
type="checkbox"
value={option.value}
checked={banFrostyOption === option.value}
onChange={handleBanFrOpt}
disabled={banFrostyOption !== '' && banFrostyOption !== option.value}
/>
<label>
{option.label}-$
{
bananaSize &&
option.price.find((price) => price.cake === bananaSize)
.priceTag
}
</label> 
</div>
))}

</div>
)}
{!isOrder && (
<div>
<h3>Select sweetener</h3>
{banSweetOptions.map((option) => (
<div key={option.value}>
<input
type="checkbox"
value={option.value}
checked={banSweetener === option.value}
onChange={handleBanSwOpt}

/>
<label>
{option.label}-$
{
bananaSize &&
option.price.find((price) => price.cake === bananaSize)
.priceTag
}
</label> 
</div>
))}

</div>
)}
</div>
);
};

export default Banana;