import React from 'react';
import { useState} from 'react';
import { useEffect} from 'react'
import image10 from '../../components/img/vanilla.png'
import jsonData from '../../data.json';
import toppingsOptions from '../../utils/ToppingsData'
import {FrostyOptsVan} from '../../utils/FrostyData'
import {vanSweetOptions} from '../../utils/SweetenerData'


const Vanilla = ({
  isOrder,
  vanTotal,
  setVanTotal,
  vanSize, 
  setVanSize,
  vanquantity,
  setVanQuantity,
  vanFrostyOption, 
  setVanFrostyOption,
  vanToppings,
  setVanToppings,
  chocolateTotal,
  bananaTotal,
  carrotTotal,
  pineTotal,
  vanSweetener,
  setVanSweetener,
  setTotal,
  cookieChocTotal,
  cookieMoTotal,
  tiramisuTotal,
  cookieAlmTotal,
  }) => 
{
const [vanToppmpbutton, setToppvanmpbutton] = useState(false);
const [vanHVtoppings, setVanHVtoppings] = useState("hiddenoptions");
const [vanFrosmpbutton, setFrosvanmpbutton] = useState(false);
const [vanHVfrosting, setVanHVfrosting] = useState("hiddenoptions");


useEffect(() => {
if (vanToppmpbutton===true) {
setVanHVtoppings("visibleoptions")
}else{
setVanHVtoppings("hiddenoptions")
}

}, [vanToppmpbutton, setVanHVtoppings]);

const handleVanToppVis = () => {

if (vanToppmpbutton===false) {
setToppvanmpbutton(true)

}else{
setToppvanmpbutton(false)
}

}


useEffect(() => {
if (vanFrosmpbutton===true) {
setVanHVfrosting("visibleoptions")
}else{
setVanHVfrosting("hiddenoptions")
}


}, [vanFrosmpbutton, setVanHVfrosting]);

const handleVanFrosVis = () => {

if (vanFrosmpbutton===false) {
setFrosvanmpbutton(true)

}else{
setFrosvanmpbutton(false)
}

}

  const handleVanFrOpt = (event) => {
    const value = event.target.value;
    if (vanFrostyOption === value) {
      setVanFrostyOption("");
      vanTotalcalc(vanquantity,vanSize, '', vanToppings, vanSweetener)
    } else {
      setVanFrostyOption(value);
      vanTotalcalc(vanquantity,vanSize, value, vanToppings, vanSweetener)
    }
  };
  const handleVanSwOpt = (event) => {
    const value = event.target.value;
    
    if(vanSweetener === value){
      if (value === "Coconut Sugar") {
        setVanSweetener("Monk Fruit");
        vanTotalcalc(vanquantity, vanSize, vanFrostyOption, vanToppings, "Monk Fruit")
      } else if(value === "Monk Fruit"){
        setVanSweetener("Coconut Sugar");
        vanTotalcalc(vanquantity,vanSize, vanFrostyOption, vanToppings, "Coconut Sugar")
      }
    }else{
      setVanSweetener(value);
      vanTotalcalc(vanquantity,vanSize, vanFrostyOption, vanToppings, value)
    }
    }
  

  const handleVanToppings = (event) => {
    const optionValue = event.target.value;

    if (vanToppings.includes(optionValue)) {
      setVanToppings(vanToppings.filter((option) => option !== optionValue));
      vanTotalcalc(vanquantity,vanSize, vanFrostyOption, vanToppings.filter((option) => option !== optionValue), vanSweetener)
    } else if (vanToppings.length < 2) {
      setVanToppings([...vanToppings, optionValue]);
      vanTotalcalc(vanquantity,vanSize, vanFrostyOption, [...vanToppings, optionValue], vanSweetener)
    }
  };

  function handleVanChange(event) {
    setVanSize(event.target.value);
    vanTotalcalc(vanquantity, event.target.value, vanFrostyOption, vanToppings, vanSweetener)
  }

  function handleVanIncrement() {
    setVanQuantity(vanquantity + 1);
    vanTotalcalc((vanquantity + 1),vanSize, vanFrostyOption, vanToppings, vanSweetener)
  }

  function handleVanDecrement() {
    if (vanquantity=== 1) {
      setVanQuantity(0);
      vanTotalcalc(0,vanSize, vanFrostyOption, vanToppings, vanSweetener)
    }else if(vanquantity>1){
      setVanQuantity(vanquantity-1);
      vanTotalcalc((vanquantity-1),vanSize, vanFrostyOption, vanToppings, vanSweetener)
    }
  }

  function vanTotalcalc(b,c,d,e,f) {
    
    const vanFrosty = d
    var addVanFrosty = 0
    const vnToppings = e
    var addVanToppings = 0
    const vanSwtner = f
    var addVanSwtner = 0

    

    if(c==="1000 grams"){
      if(vanFrosty){

        var optionset1=FrostyOptsVan.find((Frosty) => Frosty.value === vanFrosty)
        
        if(optionset1){
          var price1 = optionset1.price.find((price)=> price.cake === "1000 grams")
          if(price1){
            addVanFrosty=price1.priceTag
          }
        }
      }
      if(vanSwtner){

        var optionset2=vanSweetOptions.find((sweet) => sweet.value === vanSwtner)
       
        
        if(optionset2){
          var price2 = optionset2.price.find((price)=> price.cake === "1000 grams")
          
          if(price2){
            addVanSwtner=price2.priceTag
            
          }
        }
        
      }
      if(vnToppings){
            var optionsetarr1=vnToppings.map((topping) => toppingsOptions.find((choice)=> choice.value === topping))
        if(optionsetarr1){
          var pricearr1 = optionsetarr1.map((option) => (option.price.find((price)=> price.cake === "1000 grams")).priceTag)
          if(pricearr1){
            for (let index = 0; index < pricearr1.length; index++) {
              var element1 = pricearr1[index];
              addVanToppings+=element1
            }
          }
        }
       
      }
      if(b===1){
        const vanilla = (jsonData.vanilla.s1000+(addVanFrosty*1)+(addVanToppings*1)+(addVanSwtner*1)).toFixed(2)
        setVanTotal(vanilla)
        setTotal(((carrotTotal*1)+(vanilla*1)+(chocolateTotal*1)+(bananaTotal*1)+(pineTotal*1)+(cookieAlmTotal*1)+(cookieChocTotal*1)+(cookieMoTotal*1)+(tiramisuTotal*1)).toFixed(2))
      }else if(b>1){
        const vanilla = (((jsonData.vanilla.s1000am+(addVanFrosty*1)+(addVanToppings*1)+(addVanSwtner*1))*b)+jsonData.vanilla.s1000ab).toFixed(2)
        setVanTotal(vanilla)
        setTotal(((carrotTotal*1)+(vanilla*1)+(chocolateTotal*1)+(bananaTotal*1)+(pineTotal*1)+(cookieAlmTotal*1)+(cookieChocTotal*1)+(cookieMoTotal*1)+(tiramisuTotal*1)).toFixed(2))
      }else{
        const vanilla =0
        setVanTotal(0)
        setTotal(((carrotTotal*1)+(vanilla*1)+(chocolateTotal*1)+(bananaTotal*1)+(pineTotal*1)+(cookieAlmTotal*1)+(cookieChocTotal*1)+(cookieMoTotal*1)+(tiramisuTotal*1)).toFixed(2))
      }
    }else if(c==="650 grams"){
      if(vanFrosty){

        var optionset=FrostyOptsVan.find((Frosty) => Frosty.value === vanFrosty)
        if(optionset){
          var price = optionset.price.find((price)=> price.cake === "650 grams")
          if(price){
            addVanFrosty=price.priceTag
          }
        }
        
      }
      if(vanSwtner){

        var optionset3=vanSweetOptions.find((sweet) => sweet.value === vanSwtner)
        if(optionset3){
          var price3 = optionset3.price.find((price)=> price.cake === "650 grams")
          if(price3){
            addVanSwtner=price3.priceTag
          }
        }
        
      }
      if(vnToppings){
            var optionsetarr=vnToppings.map((topping) => toppingsOptions.find((choice)=> choice.value === topping))
        if(optionsetarr){
          var pricearr = optionsetarr.map((option) => (option.price.find((price)=> price.cake === "650 grams")).priceTag)
          if(pricearr){
            for (let index = 0; index < pricearr.length; index++) {
              var element = pricearr[index];
              addVanToppings+=element
            }
          }
        }
       
      }
      if(b===1){
        const vanilla = (jsonData.vanilla.s650+(addVanFrosty*1)+(addVanToppings*1)+(addVanSwtner*1)).toFixed(2)
        setVanTotal(vanilla)
        setTotal(((carrotTotal*1)+(vanilla*1)+(chocolateTotal*1)+(bananaTotal*1)+(pineTotal*1)+(cookieAlmTotal*1)+(cookieChocTotal*1)+(cookieMoTotal*1)+(tiramisuTotal*1)).toFixed(2))
      }else if(b>1){
        const vanilla= (((jsonData.vanilla.s650am+(addVanFrosty*1)+(addVanToppings*1)+(addVanSwtner*1))*b)+jsonData.vanilla.s650ab).toFixed(2)
        setVanTotal(vanilla)
        setTotal(((carrotTotal*1)+(vanilla*1)+(chocolateTotal*1)+(bananaTotal*1)+(pineTotal*1)+(cookieAlmTotal*1)+(cookieChocTotal*1)+(cookieMoTotal*1)+(tiramisuTotal*1)).toFixed(2))
      }else{
        const vanilla =0
        setVanTotal(0)
        setTotal(((carrotTotal*1)+(vanilla*1)+(chocolateTotal*1)+(bananaTotal*1)+(pineTotal*1)+(cookieAlmTotal*1)+(cookieChocTotal*1)+(cookieMoTotal*1)+(tiramisuTotal*1)).toFixed(2))
      }
    }else{
      const vanilla= 0
      setVanTotal(vanilla)
      setTotal(((carrotTotal*1)+(vanilla*1)+(chocolateTotal*1)+(bananaTotal*1)+(pineTotal*1)+(cookieAlmTotal*1)+(cookieChocTotal*1)+(cookieMoTotal*1)+(tiramisuTotal*1)).toFixed(2))
    }
  }


  return (
       

          <div>
            <div className='photoOrder'>
            <div className='cakesNameTotal'>
              <h3 className='cakeTitle'>Vanilla:</h3>
              <h3>${vanTotal}</h3>
            </div>
            <img className='photosOrder'src={image10} alt="vanilla cake"></img>

            </div>
            
            {isOrder===true?(
            <div>
              <h1>Vanilla Size:{vanSize}</h1>
              <h1>Quantity:{vanquantity}</h1>
              <h1>Frosting:{vanFrostyOption}</h1>
              <h1>Toppings:{vanToppings}</h1>
            </div>):
            (<div className='displayit'>
              <div>
                <label htmlFor="size-select-vanapple"><span>Select cake size:</span></label>
                <select id="size-select-vanapple" value={vanSize} onChange={handleVanChange}>
                  <option value="">Select size</option> {/* initial default option */}
                  <option value="1000 grams">1000 grams</option>
                  <option value="650 grams">650 grams</option>
                </select>
              </div>
              <div  className='btdiv'>
                <button className="btplusminus" style={{ cursor: 'pointer'}}  onClick={handleVanDecrement}>-</button>
                  <h4 className="qty">{vanquantity}</h4>
                <button className="btplusminus" style={{ cursor: 'pointer'}}  onClick={handleVanIncrement}>+</button>
              </div>
            </div>)}
            {!isOrder && (
            <div>
                     <button className='pmbutton' onClick={handleVanToppVis}>
              <h3 style={{ textDecoration: 'underline' }}>Add Toppings</h3>
                  </button>
              {toppingsOptions.map((option) => (
              <div key={option.value} className={vanHVtoppings}> 
                <input
                type="checkbox"
                value={option.value}
                checked={vanToppings.includes(option.value)}
                onChange={handleVanToppings}
                disabled={
                vanToppings.length === 2 && !vanToppings.includes(option.value)
                }
                />
                <label>{option.label}-$
                {
            vanSize &&
            option.price.find((price) => price.cake === vanSize)
              .priceTag
          }
      
                </label>
              </div>
              ))}
            </div>)}
            {!isOrder && (
            <div>
              <button className='pmbutton'  onClick={handleVanFrosVis}>
              <h3 style={{ textDecoration: 'underline' }}>Add Frosting</h3>
              </button>
              {FrostyOptsVan.map((option) => (
              <div key={option.value} className={vanHVfrosting}>
                <input
                type="checkbox"
                value={option.value}
                checked={vanFrostyOption === option.value}
                onChange={handleVanFrOpt}
                disabled={vanFrostyOption !== '' && vanFrostyOption !== option.value}
                />
                <label>
                {option.label}-$
                {
            vanSize &&
            option.price.find((price) => price.cake === vanSize)
              .priceTag
          }
                </label> 
              </div>
              ))}

            </div>
            )}
            {!isOrder && (
<div>
<h3>Select sweetener</h3>
{vanSweetOptions.map((option) => (
  <div key={option.value}>
    <input
    type="checkbox"
    value={option.value}
    checked={vanSweetener === option.value}
    onChange={handleVanSwOpt}

    />
    <label>
    {option.label}-$
    {
    vanSize &&
    option.price.find((price) => price.cake === vanSize)
    .priceTag
    }
    </label> 
  </div>
))}

</div>
            )}
           
           
           

          </div>



  );
};

export default Vanilla;