import React from 'react';
import image5 from '../../components/img/eclipseCarrot.png'
import jsonData from '../../data.json';
import toppingsOptions from '../../utils/ToppingsData'
import {FrostyOptsCarr} from '../../utils/FrostyData'
import {carrSweetOptions} from '../../utils/SweetenerData'
import { useState} from 'react';
import { useEffect} from 'react'

const Carrot = ({
  isOrder,

  carrotTotal, 
  setCarrotTotal,
  carrotSize, 
  setCarrotSize,
  carrotquantity, 
  setCarrotQuantity,
  carrFrostyOption, 
  setCarrFrostyOption,
  carrToppings, 
  setCarrToppings,
  chocolateTotal,
  bananaTotal,
  carrSweetener,
  setCarrSweetener,
  setTotal,
  pineTotal,
  vanTotal,
  cookieChocTotal,
  cookieMoTotal,
  tiramisuTotal,
  cookieAlmTotal,

  }) => 
{

  const [carrToppmpbutton, setToppcarrmpbutton] = useState(false);
const [carrHVtoppings, setCarrHVtoppings] = useState("hiddenoptions");
const [carrFrosmpbutton, setFroscarrmpbutton] = useState(false);
const [carrHVfrosting, setCarrHVfrosting] = useState("hiddenoptions");


useEffect(() => {
if (carrToppmpbutton===true) {
setCarrHVtoppings("visibleoptions")
}else{
setCarrHVtoppings("hiddenoptions")
}


}, [carrToppmpbutton, setCarrHVtoppings]);

const handleCarrToppVis = () => {

if (carrToppmpbutton===false) {
setToppcarrmpbutton(true)

}else{
setToppcarrmpbutton(false)
}

}


useEffect(() => {
if (carrFrosmpbutton===true) {
setCarrHVfrosting("visibleoptions")
}else{
setCarrHVfrosting("hiddenoptions")
}


}, [carrFrosmpbutton, setCarrHVfrosting]);

const handleCarrFrosVis = () => {

if (carrFrosmpbutton===false) {
setFroscarrmpbutton(true)

}else{
setFroscarrmpbutton(false)
}

}

  const handleCarrFrOpt = (event) => {
    const value = event.target.value;
    if (carrFrostyOption === value) {
      setCarrFrostyOption("");
      carrotTotalcalc(carrotquantity,carrotSize, "", carrToppings, carrSweetener)
    } else {
      setCarrFrostyOption(value);
      carrotTotalcalc(carrotquantity,carrotSize, value, carrToppings, carrSweetener)
    }
  };
  const handleCarrSwOpt = (event) => {
     const value = event.target.value;
    if(carrSweetener === value){
      if (value === "Coconut Sugar") {
        setCarrSweetener("Monk Fruit");
        carrotTotalcalc(carrotquantity,carrotSize, carrFrostyOption, carrToppings, "Monk Fruit")
      } else if(value === "Monk Fruit"){
        setCarrSweetener("Coconut Sugar");
        carrotTotalcalc(carrotquantity,carrotSize, carrFrostyOption, carrToppings, "Coconut Sugar")
      }
    }else{
      setCarrSweetener(value);
      carrotTotalcalc(carrotquantity,carrotSize, carrFrostyOption, carrToppings, value)
    }

  };

  const handleCarrToppings = (event) => {
    
    const optionValue = event.target.value;

    if (carrToppings.includes(optionValue)) {
      setCarrToppings(carrToppings.filter((option) => option !== optionValue));
      carrotTotalcalc(carrotquantity,carrotSize, carrFrostyOption, carrToppings.filter((option) => option !== optionValue), carrSweetener)
    } else if (carrToppings.length < 2) {
      setCarrToppings([...carrToppings, optionValue]);
      carrotTotalcalc(carrotquantity,carrotSize, carrFrostyOption, [...carrToppings, optionValue], carrSweetener)
    }
  };

  function handleCarrotChange(event) {
    setCarrotSize(event.target.value);
    carrotTotalcalc(carrotquantity, event.target.value, carrFrostyOption, carrToppings, carrSweetener)
  }

  function handleCarrotIncrement() {
    setCarrotQuantity(carrotquantity + 1);
    carrotTotalcalc((carrotquantity + 1),carrotSize, carrFrostyOption, carrToppings, carrSweetener)
  }

  function handleCarrotDecrement() {
    if (carrotquantity=== 1) {
      setCarrotQuantity(0);
      carrotTotalcalc(0,carrotSize, carrFrostyOption, carrToppings, carrSweetener)
    }else if(carrotquantity>1){
      setCarrotQuantity(carrotquantity-1);
      carrotTotalcalc((carrotquantity-1),carrotSize, carrFrostyOption, carrToppings, carrSweetener)
    }
  }

  function carrotTotalcalc(b,c,d,e,f) {
    const cFrosty = d
    var addCFrosty = 0
    const cToppings = e
    var addCToppings = 0
    const cSwtner = f
    var addCSwtner = 0

    if(c==="1000 grams"){
      if(cFrosty){

        var optionset1=FrostyOptsCarr.find((Frosty) => Frosty.value === cFrosty)
        if(optionset1){
          var price1 = optionset1.price.find((price)=> price.cake === "1000 grams")
          if(price1){
            addCFrosty=price1.priceTag
          }
        }
        
      }
      if(cSwtner){

        var optionset2=carrSweetOptions.find((Sweet) => Sweet.value === cSwtner)
        if(optionset2){
          var price2= optionset2.price.find((price)=> price.cake === "1000 grams")
          if(price2){
            addCSwtner=price2.priceTag
          }
        }
        
      }
      if(cToppings){
            var optionsetarr1=cToppings.map((topping) => toppingsOptions.find((choice)=> choice.value === topping))
        if(optionsetarr1){
          var pricearr1 = optionsetarr1.map((option) => (option.price.find((price)=> price.cake === "1000 grams")).priceTag)
          if(pricearr1){
            for (let index = 0; index < pricearr1.length; index++) {
              var element1 = pricearr1[index];
              addCToppings+=element1
            }
          }
        }
       
      }
      if(b===1){
        const carrot = (jsonData.carrot.s1000+(addCFrosty*1)+(addCToppings*1)+(addCSwtner*1)).toFixed(2)
        setCarrotTotal(carrot)
        setTotal(((carrot*1)+(bananaTotal*1)+(chocolateTotal*1)+(pineTotal*1)+(vanTotal*1)+(cookieAlmTotal*1)+(cookieChocTotal*1)+(cookieMoTotal*1)+(tiramisuTotal*1)).toFixed(2))
      }else if(b>1){
        const carrot = (((jsonData.carrot.s1000am+(addCFrosty*1)+(addCToppings*1)+(addCSwtner*1))*b)+jsonData.carrot.s1000ab).toFixed(2)
        setCarrotTotal(carrot)
        setTotal(((carrot*1)+(bananaTotal*1)+(chocolateTotal*1)+(pineTotal*1)+(vanTotal*1)+(cookieAlmTotal*1)+(cookieChocTotal*1)+(cookieMoTotal*1)+(tiramisuTotal*1)).toFixed(2))
      }else{
      const carrot=0
      setCarrotTotal(carrot)
      setTotal(((carrot*1)+(bananaTotal*1)+(chocolateTotal*1)+(pineTotal*1)+(vanTotal*1)+(cookieAlmTotal*1)+(cookieChocTotal*1)+(cookieMoTotal*1)+(tiramisuTotal*1)).toFixed(2))
      }
    }else if(c==="650 grams"){
      if(cFrosty){

        var optionset=FrostyOptsCarr.find((Frosty) => Frosty.value === cFrosty)
        if(optionset){
          var price = optionset.price.find((price)=> price.cake === "650 grams")
          if(price){
            addCFrosty=price.priceTag
          }
        }
        
      }
      if(cSwtner){

        var optionset3=carrSweetOptions.find((Sweet) => Sweet.value === cSwtner)
        if(optionset3){
          var price3= optionset3.price.find((price)=> price.cake === "650 grams")
          if(price3){
            addCSwtner=price3.priceTag
          }
        }
        
      }
      if(cToppings){
            var optionsetarr=cToppings.map((topping) => toppingsOptions.find((choice)=> choice.value === topping))
        if(optionsetarr){
          var pricearr = optionsetarr.map((option) => (option.price.find((price)=> price.cake === "650 grams")).priceTag)
          if(pricearr){
            for (let index = 0; index < pricearr.length; index++) {
              var element = pricearr[index];
              addCToppings+=element
            }
          }
        }
       
      }
      if(b===1){
        const carrot = (jsonData.carrot.s650+(addCFrosty*1)+(addCToppings*1)+(addCSwtner*1)).toFixed(2)
        setCarrotTotal(carrot)
        setTotal(((carrot*1)+(bananaTotal*1)+(chocolateTotal*1)+(pineTotal*1)+(vanTotal*1)+(cookieAlmTotal*1)+(cookieChocTotal*1)+(cookieMoTotal*1)+(tiramisuTotal*1)).toFixed(2))
      }else if(b>1){
        const carrot = (((jsonData.carrot.s650am+(addCFrosty*1)+(addCToppings*1)+(addCSwtner*1))*b)+jsonData.carrot.s650ab).toFixed(2)
        setCarrotTotal(carrot)
        setTotal(((carrot*1)+(bananaTotal*1)+(chocolateTotal*1)+(pineTotal*1)+(vanTotal*1)+(cookieAlmTotal*1)+(cookieChocTotal*1)+(cookieMoTotal*1)+(tiramisuTotal*1)).toFixed(2))
      }else{
        const carrot=0
        setCarrotTotal(carrot)
        setTotal(((carrot*1)+(bananaTotal*1)+(chocolateTotal*1)+(pineTotal*1)+(vanTotal*1)+(cookieAlmTotal*1)+(cookieChocTotal*1)+(cookieMoTotal*1)+(tiramisuTotal*1)).toFixed(2))
      }
    }else{
    const carrot= 0
      setCarrotTotal(carrot)
      setTotal(((carrot*1)+(bananaTotal*1)+(chocolateTotal*1)+(pineTotal*1)+(vanTotal*1)+(cookieAlmTotal*1)+(cookieChocTotal*1)+(cookieMoTotal*1)+(tiramisuTotal*1)).toFixed(2))
    }
  }

  return (

          <div>
            <div className='photoOrder'>
            <div  className='cakesNameTotal'>
              <h3 className='cakeTitle'>Carrot:</h3>
              <h3>${carrotTotal}</h3>
            </div>
            <img className='photosOrder'src={image5} alt="carrot cake"></img>

            </div>
            {isOrder===true?(
            <div>
              <h1>Carrot Size: {carrotSize}</h1>
              <h1>Quantity:{carrotquantity}</h1>
              <h1>Frosting:{carrFrostyOption}</h1>
              <h1>Toppings:{carrToppings}</h1>
            </div>):(
            <div>
              <div>
                <label htmlFor="size-select-carrot"><span>Select cake size:</span></label>
                <select id="size-select-carrot" value={carrotSize} onChange={handleCarrotChange}>
                <option value="">Select size</option> {/* initial default option */}
                <option value="1000 grams">1000 grams</option>
                <option value="650 grams">650 grams</option>
                </select>
              </div>
              <div  className='btdiv'>
                <button className="btplusminus" style={{ cursor: 'pointer'}} onClick={handleCarrotDecrement}>-</button>
                <h4 className="qty">{carrotquantity}</h4>
                <button className="btplusminus" style={{ cursor: 'pointer'}}  onClick={handleCarrotIncrement}>+</button>
              </div>
            </div>)}
            {!isOrder && (
               <div>
              <button className='pmbutton' onClick={handleCarrToppVis}>
              <h3 style={{ textDecoration: 'underline' }}>Add Toppings</h3>
                  </button>
              {toppingsOptions.map((option) => (
              <div key={option.value} className={carrHVtoppings} > 
                <input
                type="checkbox"
                value={option.value}
                checked={carrToppings.includes(option.value)}
                onChange={handleCarrToppings}
                disabled={
                carrToppings.length === 2 && !carrToppings.includes(option.value)
                }
                />
                <label>
                <span>     {option.label}-$
          {
            carrotSize &&
            option.price.find((price) => price.cake === carrotSize)
              .priceTag
          }
      
                  </span>
                </label>
              </div>
              ))}
            </div>)}
            {!isOrder && (
               <div>
              <button className='pmbutton'  onClick={handleCarrFrosVis}>
              <h3 style={{ textDecoration: 'underline' }}>Add Frosting</h3>
              </button>
              {FrostyOptsCarr.map((option) => (
              <div key={option.value} className={carrHVfrosting}>
                <input
                type="checkbox"
                value={option.value}
                checked={carrFrostyOption === option.value}
                onChange={handleCarrFrOpt}
                disabled={carrFrostyOption !== '' && carrFrostyOption !== option.value}
                />
                <label>
                <span>     {option.label}-$
          {
            carrotSize &&
            option.price.find((price) => price.cake === carrotSize)
              .priceTag
          }
      
                  </span>
                </label> 
              </div>
              ))}
            </div>
            )}
            {!isOrder && (
            <div>
              <h3>Select sweetener</h3>
              {carrSweetOptions.map((option) => (
              <div key={option.value}>
                <input
                type="checkbox"
                value={option.value}
                checked={carrSweetener === option.value}
                onChange={handleCarrSwOpt}
            
                />
                <label>
                <span>     {option.label}-$
          {
            carrotSize &&
            option.price.find((price) => price.cake === carrotSize)
              .priceTag
          }
      
                  </span>
                </label> 
              </div>
              ))}
            </div>
            )}
          </div>
       
  );
};

export default Carrot;