import React, { useState, useEffect } from 'react';
// import image10 from '../../components/img/separatornew1.png';
// import image11 from '../../components/img/separatornew2.png';
import image12 from '../../components/img/tiramisu.png';

const Tiramisu = () => {
  const [change, setChange] = useState(true);
  const [showFullIngredients, setShowFullIngredients] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setChange(!change);
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [change]);

  return (
    <div>
      <div className='containercarrousel'>
        {/* <img
          className='separator'
          src={change ? image10 : image11}
          alt="separator"
        /> */}
        <div className='imagescarrouselcontainter'>
          <img
            className='imagescarrousel'
            src={image12}
            alt="tiramisu"
          />
          <span className='textimagescarrousel'>Try our new Tiramisu, sweetened with low-calorie coconut sugar</span>
        </div>
        {/* <img
          className='separator'
          src={change ? image11 : image10}
          alt="separator"
        /> */}
      </div>

      <div className='newingredients'>
        <div>
        <div className='newingredients'>
          <h2 className='ingtitle'>
            Tiramisu Ingredients
          </h2>
          </div>
          <p className='ingredientsp' style={{ textAlign: 'justify', marginRight: "5px" }}>
            {showFullIngredients ? `⚠️cheese cream(lactose free)⚠️,⚠️whipping cream(lactose free)⚠️, liquid coffee, coconut sugar/monk fruit, almond flour, coconut oil, vanilla, almond milk, Mascarpone cheese, red cocoa` : `⚠️cheese cream(lactose free)⚠️,⚠️whipping cream(lactose free)⚠️, liquid coffee, ...`}
            <span><button className='ingvisible'onClick={() => setShowFullIngredients(!showFullIngredients)}>
            {showFullIngredients ? 'See Less' : 'See More'}
          </button></span>
          </p>
          
        </div>
      </div>
    </div>
  );
}

export default Tiramisu;


