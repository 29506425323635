import React from 'react';
import Canonical from '../components/Canonical';
import About from '../components/Aboutus';


const Home = () => {


  return (
    <div className='home'>
      <Canonical url="https://www.bitelandia.com/" />
      
      <div className='products'>
        <img src="https://storage.googleapis.com/bite_imp_images/engin-akyurt-uDtO1mko__w-unsplash.jpg" alt="Granadilla and Avocados" className="background-image" />
        <div className="overlay-text">
          <h3 className='landingpageh3'>Bulk orders of top-quality produce</h3>
          <p>
            <a href="./fruits_vegetables_dist" className='btn'>
              Explore Our Fresh Produce
            </a>
          </p>
        </div>
      </div>

      <div className='bakery'>
        <img src="https://storage.cloud.google.com/bitelandiaphotos/photos/FD700592-353E-48B4-83E7-1EBB7050E602.jpeg" alt="Cakes and Cookies" className="background-image2" />
        <div className="overlay-text">
          <h3 className='landingpageh3'>
            Delicious desserts, cakes, and cookies. Place your order online for convenient delivery right to your door.
          </h3>
          <p>
            <a href="./cakes_desserts_main" className='btn'>
              Order Cakes & Desserts
            </a>
          </p>
        </div>
      </div>

      <div className='cakescontent'>
  <div className='newingredients'>
    
    
    <h1>
      About Us
      </h1>
      
      
   
    </div>
    <div className='divparopening'>
    <div className='paropening'>
    <About/>
    </div>
    </div>
  </div>

    </div>
  );
};

export default Home;