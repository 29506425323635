
export const FrostyOptsCh= [
  { label: 'Chocolate Ganache', value: 'Chocolate Ganache', price:[{cake:"1000 grams", priceTag:13.0},{cake:"650 grams", priceTag:9.0}] },
  { label: 'Greek Yogurt+Cheese Cream', value: 'Greek Yogurt+Cheese Cream',  price:[{cake:"1000 grams", priceTag:9.5},{cake:"650 grams", priceTag:7.0}] },
 { label: 'Coconut Caramel(Lactose Free)', value: 'Coconut Caramel(with veggie)',  price:[{cake:"1000 grams", priceTag:9.5},{cake:"650 grams", priceTag:8.5}]},
];
export const FrostyOptsBan= [
 { label: 'Mascarpone', value: 'Mascarpone', price:[{cake:"1000 grams", priceTag:11.5},{cake:"650 grams", priceTag:9.0}]  },
 { label: 'Greek Yogurt+Cheese Cream', value: 'Greek Yogurt+Cheese Cream',  price:[{cake:"1000 grams", priceTag:9.5},{cake:"650 grams", priceTag:7.0}] },
];
export const FrostyOptsCarr= [
 { label: 'Mascarpone', value: 'Mascarpone', price:[{cake:"1000 grams", priceTag:11.5},{cake:"650 grams", priceTag:9.0}]  },
 { label: 'Coconut(with veggie milk)', value: 'Coconut(with veggie milk)', price:[{cake:"1000 grams", priceTag:11.0},{cake:"650 grams", priceTag:8.0}] },
 { label: 'Greek Yogurt+Cheese Cream', value: 'Greek Yogurt+Cheese Cream',  price:[{cake:"1000 grams", priceTag:9.5},{cake:"650 grams", priceTag:7.0}] },
 { label: 'Coconut Caramel(Lactose Free)', value: 'Coconut Caramel(Lactose Free)',  price:[{cake:"1000 grams", priceTag:9.5},{cake:"650 grams", priceTag:8.5}]},
];
export const FrostyOptsPine= [
 { label: 'Coconut(with veggie milk)', value: 'Coconut(with veggie milk)', price:[{cake:"1000 grams", priceTag:11.0},{cake:"650 grams", priceTag:8.0}] },
 { label: 'Greek Yogurt+Cheese Cream', value: 'Greek Yogurt+Cheese Cream',  price:[{cake:"1000 grams", priceTag:9.5},{cake:"650 grams", priceTag:7.0}] },
 { label: 'Coconut Caramel(Lactose Free)', value: 'Coconut Caramel(Lactose Free)',  price:[{cake:"1000 grams", priceTag:9.5},{cake:"650 grams", priceTag:8.5}]},
];
export const FrostyOptsVan= [
 { label: 'Mascarpone', value: 'Mascarpone', price:[{cake:"1000 grams", priceTag:11.5},{cake:"650 grams", priceTag:9.0}]  },
 { label: 'Coconut(with veggie milk)', value: 'Coconut(with veggie milk)', price:[{cake:"1000 grams", priceTag:11.0},{cake:"650 grams", priceTag:8.0}] },
 { label: 'Greek Yogurt+Cheese Cream', value: 'Greek Yogurt+Cheese Cream',  price:[{cake:"1000 grams", priceTag:9.5},{cake:"650 grams", priceTag:7.0}] },
 { label: 'Coconut Caramel(Lactose Free)', value: 'Coconut Caramel(Lactose Free)',  price:[{cake:"1000 grams", priceTag:9.5},{cake:"650 grams", priceTag:8.5}]},
]